import React from 'react'
// import WhatsappIcon from '../Assets/images/whatsapp.svg'

const Whatsapp = () => {
  return (
    <a href='https://walink.co/4548df' target="_blank" className='whatsapp--link'>
    </a>
  )
}

export default Whatsapp